import GATSBY_COMPILED_MDX from "/opt/build/repo/projects/thera-notes/index.mdx";
import * as React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import {graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
const ProjectDetails = ({data, children}) => {
  const image = getImage(data.mdx.frontmatter.hero_image);
  return React.createElement(Layout, null, React.createElement("h1", null, data.mdx.frontmatter.title), React.createElement(GatsbyImage, {
    image: image,
    alt: data.mdx.frontmatter.title
  }), React.createElement("p", null, "GitHub Repo: ", data.mdx.frontmatter.git_repo), children);
};
export const query = graphql`
query findProject($id: String) {
    mdx(id: {eq: $id}) {
      id
      frontmatter {
        date_posted(formatString: "MMMM D, YYYY")
        git_repo
        live_link
        slug
        title
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }  
`;
export const head = ({data}) => React.createElement(Seo, {
  title: data.mdx.frontmatter.title
});
ProjectDetails
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ProjectDetails, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
